require('./bootstrap');

// Fontawesome 5
require('@fortawesome/fontawesome-free/js/all');

// Lightbox2
import Lightbox from 'lightbox2/src/js/lightbox';

// AJAX CSRF
window.ajax_default_headers = {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
};

// Config Lightbox2
Lightbox.option({
    'imageFadeDuration': 150,
    'alwaysShowNavOnTouchDevices': true,
    'albumLabel': 'Imagen %1 de %2'
});
