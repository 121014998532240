// Functions

var self = module.exports = {
    
    getCookie: function (name) {
        name = name + '=';
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },
    
    setCookie: function (name, value, seconds) {
        let expires = '';
        if (seconds) {
            let date = new Date();
            date.setTime(date.getTime() + (seconds * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value  + expires + '; path=/; SameSite=Lax';
        return self.getCookie(name);
    },
    
    deleteCookie: function (name) {   
        document.cookie = name + '=; Max-Age=-99999999;';
        return self.getCookie(name) ? false : true;  
    },
    
    findInArray: function (array, element) {
        $.each(array, function (i, item) {
            if(item == element) {
                return i;
            }
        });
        return -1;
    }
};
