require('./libs');

import Swiper from 'swiper/bundle';

import { getCookie, setCookie, deleteCookie } from './functions';

// Accept cookies

let acceptCookieName = 'acceptedCookies';
let acceptCookieLifeTime = 60 * 60 * 24 * 31; // 31 days

$('button#acceptCookies').on('click', function () {
    let btn = $(this);
    let hideLayer = btn.data('hide');
    btn.attr('disabled', 'disabled');
    btn.html('<i class="fa fa-circle-notch fa-spin"></i> Cargando...');
    if(setCookie(acceptCookieName, true, acceptCookieLifeTime)) {
        $(hideLayer).fadeOut(400, function () {
            $(this).remove();
        });
    }
});

// New products Swiper

let newProductsSwiperDelay = 5;

var newProductsSwiper = new Swiper('#newProducts-swiper.swiper-container', {
    loop: true,
    keyboard: {
        enabled: true
    },
    autoplay: {
        delay: newProductsSwiperDelay * 1000,
        disableOnInteraction: false,
    },
    slidesPerView: 2,
    spaceBetween: 30,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

// Book

let bookCookieName = 'myBook';
let bookCookieLifeTime = 60 * 60 * 24 * 31; // 31 days = 1 month

function setBookCookie(content) {
    return setCookie(bookCookieName, JSON.stringify(content), bookCookieLifeTime);
}

function bookItemPosition(type, id) {
    let cookie = getCookie(bookCookieName);
    if(cookie) {
        cookie = JSON.parse(cookie);
        return cookie[type].indexOf(id);
    }
    return -1;
}

function bookItemIsIncluded(type, id) { // estaIncluido
    return bookItemPosition(type, id) >= 0;
}

function addItemToBook(type, id) {
    let pos = bookItemPosition(type, id);
    if(pos < 0) {
        let cookie = getCookie(bookCookieName);
        cookie = JSON.parse(cookie);
        cookie[type].push(id);
        if(setBookCookie(cookie)) {
            return true;
        }
    }
    return false;
}

$('button.removeFromBook').on('click', function (e) {
    if(confirm('¿Estás seguro que deseas eliminalo/a?')) {
        let btn = $(this);
        let type = btn.data('type');
        let id = btn.data('id');
        let position = bookItemPosition(type, id);
        if(position >= 0) {
            let cookie = getCookie(bookCookieName);
            cookie = JSON.parse(cookie);
            cookie[type].splice(position, 1);
            if(setBookCookie(cookie)) {
                location.reload();
            }
        }
    }
});

function bookItemDisableButton(id) {
    let selector = 'button.addItemToBook[data-id="' + id + '"]';
    let btn = $(selector);
    btn.attr('disabled', 'disabled');
    $('body').tooltip({
        html: true,
        selector: selector,
        title: '<i class="fa fa-check"></i> Incluído/a'
    });
}

$('button.addItemToBook').each(function(i) {
    let btn = $(this);
    let type = btn.data('type');
    let id = btn.data('id');
    if(bookItemIsIncluded(type, id)) {
        bookItemDisableButton(id);
    }
});

$('button.addItemToBook').on('click', function(e) {
    let addItem = true;
    let btn = $(this);
    let type = btn.data('type');
    let id = btn.data('id');
    let cookie = getCookie(bookCookieName);
    if(cookie) {
        addItem = !bookItemIsIncluded(type, id);
    } else {
        cookie = {
            'activities': [],
            'products': []
        };
        setBookCookie(cookie);
    }
    if(addItem) {
        if(addItemToBook(type, id)) {
            bookItemDisableButton(id);
            $('#addItemToBookModal').modal('show');
        }
    }
});

$('form#sendBook').on('submit', function(e) { // #EnviarLibro
    e.preventDefault();
    let form = $(this);
    let data = form.serializeArray();
    $('div.activity').each(function (i) {
        let item = $(this);
        data.push({
            name: 'activities[' + i + ']',
            value: item.data('id')
        });
    });
    $('div.product').each(function (i) {
        let item = $(this);
        data.push({
            name: 'products[' + i + ']',
            value: item.data('id')
        });
    });
    let btn = form.find('button[type="submit"]').eq(0);
    let btnContent = null;
    let errorLayer = $('div#bookErrorForm');
    $.ajax({
        url: form.attr('action'),
        method: form.attr('method'),
        data: data,
        dataType: 'json',
        beforeSend: function() {
            btnContent = btn.html();
            btn.attr('disabled', 'disabled');
            btn.html('<i class="fa fa-circle-notch fa-spin"></i> Cargando ...');
            errorLayer.addClass('d-none');
        },
        success: function(data) {
            deleteCookie(bookCookieName);
            alert(data.message);
            location.reload();
        },
        error: function(jqXHR, textStatus, errorThrown) {
            let errorsList = errorLayer.find('ul').eq(0);
            errorsList.empty();
            try {
                $.each(jqXHR.responseJSON.errors, function (i, value) {
                    errorsList.append('<li>' + value + '</li>');
                });
                errorLayer.removeClass('d-none');
            } catch(e) {
                console.warn(jqXHR, textStatus, errorThrown);
                alert(errorThrown);
            }
        },
        complete: function() {
            btn.html(btnContent);
            btn.removeAttr('disabled');
        }
    });
});
